import { render, staticRenderFns } from "./ManageBuildings.vue?vue&type=template&id=04a68feb&scoped=true&"
import script from "./ManageBuildings.vue?vue&type=script&lang=ts&"
export * from "./ManageBuildings.vue?vue&type=script&lang=ts&"
import style0 from "./ManageBuildings.vue?vue&type=style&index=0&id=04a68feb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04a68feb",
  null
  
)

/* custom blocks */
import block0 from "./ManageBuildings.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QIcon,QSeparator});
