







































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Member from './admin/Member.vue';

@Component
export default class ManageBuildings extends Vue {
  public $store: any;
  public $q: any;
  public buildings: any = [];
  public viewType: string = 'cards';

  public created() {
    this.viewType = localStorage.getItem('buildingsViewType') || 'cards';
    this.$store.dispatch('ensureBuildings')
      .then((response: any) => {
        this.buildings = response;
      });
  }

  get buildingsList() {
    return this.buildings.sort((a: any, b: any) => {
      return a.name.localeCompare(b.name);
    });
  }

  private setViewType(vt: string) {
    this.viewType = vt;
    localStorage.setItem('buildingsViewType', vt);
  }

  private getBuildingIcon(building: any) {
    return this.$store.getters.getBuildingIcon(building);
  }

  get cardCss() {
    if (this.$q.screen.width < 800) {
      return 'col-12';
    } else if (this.$q.screen.md) {
      return 'col-6';
    } else if (this.$q.screen.lg) {
      return 'col-4';
    } else if (this.$q.screen.xl) {
      return 'col-3';
    }
    return 'col-6';
  }

  private getAnalyzerCount(building: any) {
    let count = 0;
    for (const level of building.levels) {
      for (const area of level.areas) {
        if (area.analyzer !== null) {
          count += 1;
        }
      }
    }
    return count;
  }

}
